<template>
  <div class="my-user qjc-theme-bg">
    <!-- <van-nav-bar title="記錄中心" left-arrow class="qjc-nav-bar" @click-left="$router.back()" /> -->
    <div class="user">

      <div class="list qjc-texta-l qjc-fts-32">

        <van-cell center title="歷史記錄" is-link to="/user/history">
          <van-image slot="icon" width="0.32rem" height="0.33rem" :src="require('@/assets/images/lishijilu.png')" />
        </van-cell>
      </div>
    </div>

    <!-- 底部導航 -->
    <van-tabbar v-model="navIcon.active" active-color="#6681FA" inactive-color="#9DA6B9" safe-area-inset-bottom>
      <van-tabbar-item name="home" to="/">
        <span>首頁</span>
        <img slot="icon" slot-scope="props" :src="props.active ? navIcon.home.active : navIcon.home.normal">
      </van-tabbar-item>
      <van-tabbar-item name="user" to="/user">
        <span>我的</span>
        <img slot="icon" slot-scope="props" :src="props.active ? navIcon.user.active : navIcon.user.normal">
      </van-tabbar-item>
    </van-tabbar>

    <!-- 評估未完成彈窗 -->


  </div>
</template>

<script>
import { isPay } from '@/js/axios'

import Vue from 'vue'
import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant'
Vue.use(NavBar)
  .use(Image)
  .use(Cell)
  .use(Button)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Toast)
  .use(Popup)
  .use(Dialog);

export default {
  name: 'user',
  data () {
    return {
      name: '',//姓名
      mobile: '',
      navIcon: {
        active: 'user',
        home: {
          normal: require('@/assets/images/shouye1.png'),
          active: require('@/assets/images/shouye.png')
        },
        study: {
          normal: require('@/assets/images/study.png'),
          active: require('@/assets/images/study_active.png')
        },
        learn: {
          normal: require('@/assets/images/learn.png'),
          active: require('@/assets/images/learn-active.png')
        },
        user: {
          normal: require('@/assets/images/wode.png'),
          active: require('@/assets/images/wode2.png')
        }
      },

      complete: false,//問卷是否完成

      yjqz: false// 未完成報告是否為疫境求真
    }
  },
  computed: {

  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.my-user {
  position: relative;
  width: 100%;
  height: 100vh;
}

.user {
  padding: 0.24rem;
  background-size: 100% 2.5rem;



  .list {
    &>.van-cell {
      margin-top: 0.24rem;
    }

    .van-cell {
      height: 1.28rem;
      font-size: 0.32rem;
      font-weight: 500;

      &:not(:last-child)::after {
        border-bottom: 1px solid #DCDEE5;
        left: 0.32rem;
        right: 0.32rem;
      }

      .van-image {
        margin-right: 0.17rem;
      }
    }
  }


}
</style>
